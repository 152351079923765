export default{
	data(){
		return{
			credit_status_config:{
				0:'',
				1:'已挂账',
				2:'已清账',
			}
		}
	},
	methods:{
		/**
		 * 赠送状态
		 * @param {Object} item
		 */
		 giveStatusName(item){
			if(item.status){
				switch(item.status){
					case 1:
						return '已赠送';
					case 2:
						return '已取消';
					case 3:
						return '审核中';
					case 4:
						return '已拒绝取消';
				}
			}
			return ''
		},
		/**
		 * 返回退换状态
		 * @param {Object} item
		 */
		returnRefundStatusName(item){
			if(item.refund_status){
				switch(parseInt(item.refund_status)){
					case 1:
						return '退换货审核中';
					case 2:
						return '退换货审核通过';
					case 3:
						return '退换货审核被拒绝';
				}
			}
			return ''
		},
		/**
		 * 根据订单状态返回状态名称
		 */
		returnStatusName(item){
			if(item.posterior_knot && item.status != 2){ // 后结
				switch(parseInt(item.posterior_knot_status)){
					case 1:
						return '后结待审核';
					case 2:
						return '后结审核通过,待支付';
					case 3:
						return '后结已拒绝';
					case 4:
						return '取消订单待审核';
					case 5:
						return '订单已取消';
					case 6:
						return '拒绝取消此订单';
				}
			}else if(parseInt(item.status)){
				switch(item.status){
					case 1:
						return '未结算';
					case 2:
						return '已支付';
					case 3:
						return '待确认';
					case 4:
						return '订单已取消';
				}
			}
			return ''
		}
	}
}