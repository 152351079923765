import store from '@/store'
export default{
	data(){
		return{
			
		}
	},
	methods:{
		/**
		 * 获取商家信息的某一项数据
		 * @param {Object} key
		 */
		getMerchantInfoField(key){
			let list = this.getMerchantInfo()
			for(let i in list){
				if(list[i].name === key){
					return list[i]
				}
			}
		},
		getMerchantInfo(){
			let merchant_info = store.state.merchant_info
			if(!merchant_info){
				this.$message.warning('系统已更新，请重新登录')
				this.$router.push({
					path:'/login'
				})
			}
			return merchant_info
		}
	}
}