<template>
    <div class="table-components">
        <el-table
            :data="table.list"
            border
            ref="myTable"
            style="width: 100%"
            :height="table.height"
            :row-class-name="tableRowClassName"
            :row-key="rowKeyFun"
            :highlight-current-row="table.highlight_current_row"
            @current-change="handleCurrentChange"
            @selection-change="handleSelectionChange">
            <el-table-column v-if="table.is_expand" type="expand">
                <template #default="scope">
                    <slot name="expand" :scope="scope"></slot>
                </template>
            </el-table-column>
            <el-table-column v-if="table.is_index" type="index" align="center"></el-table-column>
            <el-table-column  :selectable="selectableFun" v-if="table.is_check" type="selection" width="55"></el-table-column>
            <el-table-column  :fixed="item.fixed" header-align="center" align="center" v-for="(item,index) of table.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width?item.width:null">
                <template #header="{column,$index}">
                    <slot v-if="table.is_custom_header" name="header" :column="column" :index="$index"></slot>
                    <div v-else>{{column.label}}</div>
                </template>
                <template #default="scope">
                    <slot name="default" :scope="scope" :item="item" :index="index"></slot>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import { defineComponent, watch, ref, toRefs } from "vue"
export default defineComponent({
    name:'table-component',
    props:{
        table:{
            type:Object,
            default:{}
        }
    },
    setup(props,context) {
        let myTable = ref(null)

        watch(()=>props.table,((newVal,oldVal)=>{
            console.log('newVal',newVal)
        }))
        /**
         * ����д�����ѡ�л�ѡ�и�ѡ��
         */
        function handleRowClick(row, column, event){
            if(props.table.is_row_click){
                myTable.value.toggleRowSelection(row);
            }
        }

        function rowKeyFun(row){
            if(props.table.list.length>0){
                return row.id
            }else{
                return ''
            }
        }
        function toggleRowSelection(){
            return myTable.value.toggleRowSelection(...arguments)
        }
        function tableRowClassName({row, rowIndex}) {
            if(row.disabled){
                return 'disabled';
            }
            return '';
        }
        function handleSelectionChange(){
            context.emit('handleSelectionChange',...arguments)
        }

        function handleCurrentChange(){
            context.emit('handleCurrentChange',...arguments)
        }

        function clearSelection(){
            return myTable.value.clearSelection(...arguments)
        }

        function selectableFun(row,index){
            if(row.disabled){
                return false
            }
            return true
        }


        return{
            table:props.table,
            myTable,
            clearSelection,
            rowKeyFun,
            toggleRowSelection,
            tableRowClassName,
            handleSelectionChange,
            handleCurrentChange,
            selectableFun
        }
    }
})
</script>
<style lang="scss" scoped>
.table-components{
    & /deep/ .el-table{
        .color-row-1 {
            background: rgb(224, 236, 223);
        }
        .color-row-2 {
            background: rgb(247, 225, 226);
        }
        .el-table__row{
            cursor: pointer;
            &.disabled{
                background: rgb(245, 247, 250)!important;
                color: #C0C4CC!important;
                cursor: not-allowed!important;
                td{
                    background: rgb(245, 247, 250)!important;
                    color: #C0C4CC!important;
                    cursor: not-allowed!important;
                }
                &:hover td{
                    background: rgb(245, 247, 250)!important;
                    color: #C0C4CC!important;
                    cursor: not-allowed!important;
                }
            }
        }
    }
}
</style>
