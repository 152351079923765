import {wineWarehouseApi} from '@/api'
export default{
	data(){
		return{
			wine_status_config:{
                1:'待入库',
                2:'等待取酒',
                3:'已取出',
                4:'已存入',
                5:'已过期',
                6:'已过期入库',
                9:'存酒拒绝',
                10:'取酒拒绝',
            }
		}
	},
	methods:{
		/**
		 * 确认入库
		 * @param {*} row 
		 * @param {*} status  //1确认存入 2拒绝存入
		 */
        takeout(row,status){
            let message = ''
            if(status == 1){
                message = '确认存入'
            }else if(status == 2){
                message = '拒绝存入'
            }
            this.$confirm(`是否${message}${row.alcohol_odd_num}存酒单?`, message, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
               wineWarehouseApi.wineConfirmDeposit({
                storing_wine_id:row.storing_wine_id,
                   status, 
                }).then(res=>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
					this.$emit('refreshList')
                })
            }).catch(() => {
                     
            });
        },
		/**
		 * 存取酒打印
		 * @param {*} row 
		 * @param {*} type 1存酒 2取酒 
		 */
        printing(row,status){
            let id = 0
            if(status == 1){
                id = row.storing_wine_id
            }else if(status == 2){
                id = row.take_wine_id
            }else{
                this.$message.warning('打印类型错误')
                return;
            }
            this.$confirm(`是否确认打印?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                wineWarehouseApi.wineWinePrinter({
                    id,
                    status
                }).then(res=>{
                    this.$message.success('调用成功')
                })
            })
            
        },
		/**
         * 确认取出
         * @param {*} row 
         * @param {*} status  1同意 2拒绝
         */
        depositFun(row,status){
            let message = ''
            if(status == 1){
                message = '确认取出'
            }else if(status == 2){
                message = '拒绝取出'
            }
            this.$confirm(`是否${message}${row.take_alcohol_odd_num}取出单?`, message, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                wineWarehouseApi.wineConfirmRemoval({
                    take_wine_id:row.take_wine_id,
                    status
                }).then(res=>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    this.$emit('refreshList')
                })
            }).catch(() => {
                     
            });
        },
	}
}