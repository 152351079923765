import { compareTime, customFormatFullTime } from "@/assets/js/utils";
export default{
	data(){
		return{
			
		}
	},
	methods:{
		/**
         * 设置默认时间
         */
		 setDefaultDate(){
            if(this.search_config.form.type == 1){ // 系统时间
                let daySart=new Date(new Date(new Date().toLocaleDateString()).getTime())
                let dayEnd=new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
                this.search_config.form.times = [daySart,dayEnd]
            }else{ // 营业日    
				let date = this.setDefaultYYRDate()
                this.search_config.form.times = [date,date]
            }
        },
		/**
		 * 设置营业日
		 */
		setDefaultYYRDate(){
			// 商家营业时间 -- 后台设置的
			let merchants_bus_start_time = this.getMerchantInfoField('merchants_bus_start_time')
			// 商家营业时间 -- 时分秒格式
			let merchant_start_time = customFormatFullTime(new Date(parseInt(merchants_bus_start_time.value)),'h:m:s')
			// 当前时间 -- 时分秒格式
			let current_time = customFormatFullTime(new Date(),'h:m:s')
			
			let date = ''
			// 已经开业
			if(compareTime(merchant_start_time,current_time)){
				date = customFormatFullTime(new Date(),'YYYY-MM-DD')
			}else{ // 没有开业 减一天（查询昨天的）
				date = customFormatFullTime(new Date().getTime()-86400000,'YYYY-MM-DD')
			}
			return date
		},
		/**
         * 处理搜索的开始时间格式和结束时间格式
         * type 0返回年与日 1返回年月日时分秒
         */
		 handlerTimes(config = {}){
            let start_time = ''
            let end_time = ''
            if(this.search_config.form.times && this.search_config.form.times.length){
                if(config.type === 1 && this.search_config.form.type == 1){ //系统时间
                    start_time = customFormatFullTime(this.search_config.form.times[0],'YYYY-MM-DD h:m:s')
                    end_time = customFormatFullTime(this.search_config.form.times[1],'YYYY-MM-DD h:m:s')
                }else{
                    start_time = customFormatFullTime(this.search_config.form.times[0],'YYYY-MM-DD')
                    end_time = customFormatFullTime(this.search_config.form.times[1],'YYYY-MM-DD')
                }
            }
            return {
                start_time,
                end_time
            }
        },
	}
}